import utils from '../utils/utils'
import globalVar from '../utils/globalVar'

class Module_01_Header {
  constructor() {
    let cache = {};
    let follower, init, mouseX, mouseY, positionElement, printout, timer;
    follower = document.querySelector('.btn_popup');
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
      cache.header = document.querySelectorAll('.module-01-header.type_home');
    }
    let registerEvents = () => {
        if(globalVar.desktop.matches){
            for (let i = 0; i < cache.header.length; i++) {
                cache.header[i].addEventListener("mouseenter", function(){
                    window.addEventListener('mousemove', cursor);
                });
                cache.header[i].addEventListener("mouseleave", function(){
                    window.removeEventListener('mousemove', cursor);
                });
                
            }   
        }
    }

    let cursor = (e) => {
        setTimeout(() => {
            follower.style.top = e.pageY - (follower.offsetHeight/2) + 'px';
            follower.style.left = e.pageX - (follower.offsetWidth/2) + 'px';
        }, 100);
    }

    initFunc();
  }
}
export { Module_01_Header }
