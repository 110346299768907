import utils from '../utils/utils'
import anime from '../libs/anime'

class Nav {
  constructor() {
    let cache = {};
    let scrollPos;
    let header_class = "default";
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
      cache.section = document.querySelectorAll('section');
      cache.nav = document.querySelector('.nav .menu_wrap');
      cache.navmobileBtn = document.querySelector('.nav_mobile');
      cache.header = document.querySelector('header');
      init();
    };

    let registerEvents = () => {
      window.addEventListener('scroll', scrollDetect);
      cache.navmobileBtn.addEventListener("click", openNav);
    };

    let init = () => {
      const section = cache.section[0];
      header_class = section.dataset.header;
      cache.header.classList.add(header_class);
      anime({
        targets: cache.header,
        opacity: [0,1],
        translateY: [-20,0],
        duration: 400,
        easing: 'easeOutQuad'
      });
    }

    let scrollDetect = function() {
      let up = false;
      if(scrollPos > window.pageYOffset) {
        up = true;
      }
      scrollPos = window.pageYOffset;
      for (let i = 0; i < cache.section.length; i++) {
        const el = cache.section[i];
        let sec_header = el.dataset.header;
        if(!sec_header) {
          sec_header = "default";
        }
        if(((scrollPos+100) > utils.getOffsetTop(el)) && (scrollPos < (utils.getOffsetTop(el)+el.offsetHeight)) && (sec_header !== header_class) ) {
          cache.header.classList.remove(header_class);
          header_class = sec_header;
          if(!cache.header.classList.contains(header_class)) {
            cache.header.classList.add(header_class);
          }
        }
      }

      if(!up && (scrollPos > window.innerHeight) && !cache.header.classList.contains('up')) {
        cache.header.classList.add('up');
        anime({
          targets: cache.header,
          translateY: ['0%', '-100%'],
          duration: 800,
          easing: 'easeOutQuad'
        });
      } else if(up && (scrollPos > window.innerHeight) && cache.header.classList.contains('up')) {
        cache.header.classList.remove('up');
        anime({
          targets: cache.header,
          translateY: ['-100%', '0%'],
          duration: 800,
          easing: 'easeOutQuad',
        });
      }
    }

    let openNav = () => {
      cache.navmobileBtn.removeEventListener("click", openNav);
      cache.nav.style.display="flex";
      document.querySelector('body').classList.add('overflow');
      document.querySelector('html').classList.add('overflow');
      cache.header.classList.add('open');
      anime({
        targets: cache.header.querySelector('.bg'),
        opacity: [0,1],
        scale: [1, 130],
        duration: 800,
        easing: 'easeOutQuad'
      });
      anime({
        targets: cache.nav,
        opacity: [0,1],
        translateX: [250,0],
        delay:200,
        duration: 400,
        easing: 'easeOutQuad',
        complete: function() {
          cache.navmobileBtn.addEventListener("click", closeNav);
        }
      });
    }

    let closeNav = () => {
      cache.navmobileBtn.removeEventListener("click", closeNav);
      document.querySelector('body').classList.remove('overflow');
      document.querySelector('html').classList.remove('overflow');
      cache.header.classList.remove('open');

      anime({
        targets: cache.header.querySelector('.bg'),
        opacity: [1,0],
        scale: [130, 1],
        duration: 800,
        easing: 'easeOutQuad'
      });
      anime({
        targets: cache.nav,
        opacity: [1,0],
        translateX: [0,250],
        duration: 400,
        easing: 'easeOutQuad',
        complete: function() {
          cache.navmobileBtn.addEventListener("click", openNav);
          cache.nav.style.display="none";
        }
      });
    }

    initFunc();
  };
}
export { Nav }
