// import utils from '../utils/utils'
// import anime from '../libs/anime'
// import globalVar from '../utils/globalVar'
// import { App } from '../app'
//
// class AjaxPages {
//   constructor() {
//     let cache = {};
//     const self = this;
//     const getUrl = window.location;
//     const getUrlHref = getUrl.href;
//     let baseUrl = getUrl .protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[0];
//     let link = [];
//
//     let initFunc = () => {
//       initCache();
//       registerEvents();
//     }
//
//     let initCache = () => {
//       cache.transition = document.querySelector('.transition');
//       if (baseUrl != null && baseUrl.length > 0 && baseUrl.charAt(baseUrl.length - 1) == '/') {
//         baseUrl = baseUrl.substring(0, baseUrl.length - 1);
//       }
//     };
//
//     let registerEvents = () => {
//       let link = document.querySelectorAll('a[href*="'+baseUrl+'"]');
//       for (let i = 0; i < link.length; i++) {
//         if(!link[i].classList.contains('no_ajax')) {
//           link[i].addEventListener("click", ajaxNav);
//         }
//       }
//       window.addEventListener('popstate',back);
//     };
//
//     let ajaxNav = function(e) {
//       utils.disableScroll();
//       e.preventDefault();
//       let href = this.href;
//       cache.transition.style.display = "block";
//       setTimeout(function(){ ajaxFunc(href); }, 300);
//       history.pushState(null, null, href);
//     }
//
//     let back = function(e) {
//       utils.disableScroll();
//       event.preventDefault();
//       let href = this.href;
//       cache.transition.style.display = "block";
//       setTimeout(function(){ ajaxFunc(href); }, 300);
//     }
//
//     let ajaxFunc = function(url) {
//       link = document.querySelectorAll('a[href*="'+baseUrl+'"]');
//       for (let i = 0; i < link.length; i++) {
//         if(!link[i].classList.contains('no_ajax')) {
//           link[i].removeEventListener("click", ajaxNav);
//         }
//       }
//
//       jQuery.ajax({
//         url: url,
//         cache: false,
//         success: function(data){
//         // callback
//         let title = jQuery(data).filter('title').text();
//           document.title = title;
//           let contenuDiv = jQuery(data).filter('#contenuPage')[0];
//
//           let headIni = data.toLowerCase().indexOf("<head");
//           let headEnd = data.toLowerCase().indexOf("</head>");
//           headIni = data.indexOf(">", headIni + 1) + 1;
//           var headHTML = data.substring(headIni, headEnd);
//           let head = jQuery("head").html(headHTML);
//
//
//           let footer = jQuery(data).filter('footer')[0];
//           let header = jQuery(data).filter('header')[0];
//           let parser = new DOMParser();
//           let doc = parser.parseFromString(data, "text/html");
//           let docClass = doc.body.getAttribute('class');
//           parser = doc = null;
//
//           jQuery('#contenuPage').fadeOut('100',function(){
//             jQuery('body').removeClass();
//             jQuery('body').addClass(docClass);
//             jQuery('head').html(head.html());
//             jQuery('footer').html(footer.innerHTML);
//             jQuery('header').html(header.innerHTML);
//             jQuery('#contenuPage').html(contenuDiv.innerHTML).fadeIn('0');
//
//             window.scrollTo(0, 0);
//             utils.enableScroll();
//             window.removeEventListener('popstate',back);
//
//
//             let app = new App();
//
//               cache.transition.style.display = "none";
//
//           });
//         }
//       });
//     }
//
//     initFunc();
//   };
// }
// export { AjaxPages }
//
//
