import { AjaxPages } from './components/ajaxpages'
import { Nav } from './components/nav'
import { ScrollAnime } from './components/scrollAnime'
import { PopUp } from './components/popup'

import { Module_01_Header } from './modules/module-01-header'
import { Module_02_Logos } from './modules/module-02-logos'
import { Module_06_Steps } from './modules/module-06-steps'
import { Module_11_Plan_Slider } from './modules/module-11-plans-slider'

import { Archives } from './pages/archives'

class App {
  constructor() {
    // const ajaxpages = new AjaxPages;
    const nav = new Nav;
    const scrollAnime = new ScrollAnime;

    if(document.querySelector('.popup')) {
      const popup = new PopUp;
    }
    if(document.querySelector('.module-01-header.type_home')) {
      const module_01_Header = new Module_01_Header;
    }
    if(document.querySelector('.module-02-logos')) {
      const module_02_logos = new Module_02_Logos;
    }
    if(document.querySelector('.module-06-steps')) {
      const module_06_steps = new Module_06_Steps;
    }
    if(document.querySelector('.module-11-plans-slider')) {
      const module_11_plan_slider = new Module_11_Plan_Slider;
    }
    if(document.querySelector('.template-archive')) {
      const archives = new Archives;
    }
  }
}
export { App }

window.onload = function() {
  let app = new App();
};
