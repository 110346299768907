import utils from '../utils/utils'
import anime from '../libs/anime'
import globalVar from '../utils/globalVar'

class Module_06_Steps {
  constructor() {
    let index = 0;
    let prev;
    let scrollPos;
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
      scrollDetect();
    }

    let initCache = () => {
      cache.img = document.querySelectorAll('.module-06-steps .col_img .img');
      cache.txt = document.querySelectorAll('.module-06-steps .col_left .step');
      cache.prog = document.querySelector('.line .line_inner');
      cache.number = document.querySelector('.number_container .number');
      prev = cache.txt[0];
      let percent = 100/cache.img.length;
      cache.prog.style.height = percent+'%';
    };

    let registerEvents = () => {
      if(!globalVar.tablet.matches) {
        window.addEventListener('scroll', scrollDetect);
      }
    };

    let scrollDetect = function() {
      let up = false;
      if(scrollPos > window.pageYOffset) {
        up = true;
      }
      scrollPos = window.pageYOffset;

// Img
      for (let i = 0; i < cache.img.length; i++) {
        const el = cache.img[i];
        if(((scrollPos+window.innerHeight/2) > utils.getOffsetTop(el)) && (scrollPos+window.innerHeight/2 < (utils.getOffsetTop(el)+el.offsetHeight)) && !el.classList.contains('animated') && !up) {

            if(index !== parseInt(el.dataset.index)) {
              el.classList.add('animated');
              if(!cache.img[index].classList.contains('animated')) {
                cache.img[index].classList.add('animated');
              }
              index = parseInt(el.dataset.index);
              let newnb = parseInt(el.dataset.index)+1;
              cache.number.innerHTML = newnb;

              let target = cache.txt[index];
              if(prev && prev !== target) {
                anime({
                  targets: prev,
                  opacity: [1,0],
                  duration: 300,
                  translateX: [0, -10],
                  easing: 'easeOutQuad',
                });
              }
              
              anime({
                targets: target,
                opacity: [0,1],
                duration: 300,
                translateX: [10, 0],
                easing: 'easeOutQuad',
              });

              prev = target;
              let percent = ((index+1)/cache.img.length)*100;
              let height = percent+'%';
              anime({
                targets: cache.prog,
                height: height,
                duration: 300,
                easing: 'easeOutQuad',
              });
            }
        }
        if(((scrollPos+window.innerHeight/2) < (utils.getOffsetTop(el)+el.offsetHeight)) && (scrollPos+window.innerHeight/2 > utils.getOffsetTop(el)) && el.classList.contains('animated') && up) {
          if(index !== parseInt(el.dataset.index)) {
            el.classList.remove('animated');
            if(cache.img[index].classList.contains('animated')) {
              cache.img[index].classList.remove('animated');
            }
            index = parseInt(el.dataset.index);
            let target = cache.txt[index];
            let newnb = parseInt(el.dataset.index)+1;
              cache.number.innerHTML = newnb;

            if(prev && (prev !== target)) {
              anime({
                targets: prev,
                opacity: [1,0],
                duration: 300,
                translateX: [0, -10],
                easing: 'easeOutQuad',
              });
            }

              anime({
                targets: target,
                opacity: [0,1],
                duration: 300,
                translateX: [10, 0],
                easing: 'easeOutQuad',
              });

              prev = target;
              let percent = ((index+1)/cache.img.length)*100;
              let height = percent+'%';
              anime({
                targets: cache.prog,
                height: height,
                duration: 300,
                easing: 'easeOutQuad',
              });
          }
        }
      }

    }

    initFunc();
  };
}
export { Module_06_Steps }
