import anime from '../libs/anime';
import Hammer from '../libs/hammer';

class Module_11_Plan_Slider {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    };

    let initCache = () => {
      cache.plans = document.querySelectorAll('.module-11-plans-slider');
    };

    let registerEvents = () => {
      for (let i = 0; i < cache.plans.length; i++) {
        let cards = cache.plans[i].querySelectorAll('.card-plan');
        for (let ii = 0; ii < cards.length; ii++) {
          const el = cards[ii];
          el.addEventListener('mouseenter', hoverInCard);

          el.addEventListener('click', (event) => {
            if (el.classList.contains('right-slide')) {
              event.preventDefault();
              slideRight(cache.plans[i]);
            }
          });

          if (ii === 1) {
            el.classList.add('right-slide');
          }
        }

        if (cache.plans[i].querySelector('.slider_wrap')) {
          if (cards.length > 2) {
            let arrow_left = cache.plans[i].querySelectorAll('.arrow_left');
            for (let ii = 0; ii < arrow_left.length; ii++) {
              arrow_left[ii].addEventListener('click', function () {
                slideLeft(cache.plans[i]);
              });
            }

            let arrow_right = cache.plans[i].querySelectorAll('.arrow_right');
            for (let ii = 0; ii < arrow_right.length; ii++) {
              arrow_right[ii].addEventListener('click', function () {
                slideRight(cache.plans[i]);
              });
            }

            let hammertime = new Hammer(cache.plans[i].querySelector('.slider_wrap'));
            hammertime.on('swipe', function (ev) {
              if (ev.direction == 2) {
                slideRight(cache.plans[i]);
              }
              if (ev.direction == 4) {
                slideLeft(cache.plans[i]);
              }
            });
          }
        }
      }
    };

    let hoverInCard = function () {
      let card = this;
      if (card.classList.contains('right-slide')) {
        return;
      }
      let txt = card.querySelector('.card-plan_txt');
      card.removeEventListener('mouseenter', hoverInCard);
      card.addEventListener('mouseleave', hoverOutCard);
      txt.style.display = 'block';
      txt.style.height = 'auto';
      let height = txt.offsetHeight;
      txt.style.height = 0;
      anime({
        targets: txt,
        height: height,
        duration: 300,
        easing: 'easeOutQuad',
      });
    };

    let hoverOutCard = function () {
      let card = this;
      let txt = card.querySelector('.card-plan_txt');
      card.addEventListener('mouseenter', hoverInCard);
      card.removeEventListener('mouseleave', hoverOutCard);
      anime({
        targets: txt,
        height: 0,
        duration: 300,
        easing: 'easeOutQuad',
        complete: function () {
          // txt.style.display = "none";
        },
      });
    };

    let updateSlides = function (parent) {
      let slides = parent.querySelectorAll('.card-plan');
      slides.forEach((slide) => {
        slide.classList.remove('right-slide');
      });

      let index = parseInt(parent.querySelector('.slider_wrap').dataset.index);
      let newRightSlideIndex = (index + 1) % slides.length;
      slides[newRightSlideIndex].classList.add('right-slide');
    };

    let slideLeft = function (parent) {
      let arrowL = parent.querySelector('.arrow_left');
      let arrowR = parent.querySelector('.arrow_right');
      let slider = parent.querySelector('.slider_wrap');
      let slides = slider.querySelectorAll('.card-plan');
      let index = parseInt(slider.dataset.index);
      let nxtIndex = index;

      let currentSlide = slides[index];

      arrowL.style.pointerEvents = 'none';
      arrowR.style.pointerEvents = 'none';

      if (index == 0) {
        index = slides.length - 1;
      } else {
        index--;
      }

      let targetSlide = slides[index];
      slider.dataset.index = index;

      if (nxtIndex == slides.length - 1) {
        nxtIndex = 0;
      } else {
        nxtIndex++;
      }
      let nxtSlide = slides[nxtIndex];

      anime({
        targets: currentSlide,
        translateX: ['0%', '105%'],
        duration: 400,
        easing: 'easeOutQuad',
      });

      targetSlide.style.display = 'block';
      anime({
        targets: targetSlide,
        translateX: ['-105%', '0%'],
        duration: 400,
        easing: 'easeOutQuad',
      });

      anime({
        targets: nxtSlide,
        translateX: ['105%', '210%'],
        duration: 400,
        easing: 'easeOutQuad',
        complete: function () {
          nxtSlide.style.display = 'none';
          arrowL.style.pointerEvents = 'all';
          arrowR.style.pointerEvents = 'all';
          updateSlides(parent);
        },
      });
    };

    let slideRight = function (parent) {
      let arrowL = parent.querySelector('.arrow_left');
      let arrowR = parent.querySelector('.arrow_right');
      let slider = parent.querySelector('.slider_wrap');
      let slides = slider.querySelectorAll('.card-plan');
      let index = parseInt(slider.dataset.index);

      let currentSlide = slides[index];
      arrowL.style.pointerEvents = 'none';
      arrowR.style.pointerEvents = 'none';

      if (index == slides.length - 1) {
        index = 0;
      } else {
        index++;
      }

      let targetSlide = slides[index];
      slider.dataset.index = index;

      if (index == slides.length - 1) {
        index = 0;
      } else {
        index++;
      }

      let nxtSlide = slides[index];

      anime({
        targets: currentSlide,
        translateX: ['0%', '-105%'],
        duration: 400,
        easing: 'easeOutQuad',
        complete: function () {
          currentSlide.style.display = 'none';
          arrowL.style.pointerEvents = 'all';
          arrowR.style.pointerEvents = 'all';
          updateSlides(parent);
        },
      });

      anime({
        targets: targetSlide,
        translateX: ['105%', '0%'],
        duration: 400,
        easing: 'easeOutQuad',
      });

      nxtSlide.style.display = 'block';
      anime({
        targets: nxtSlide,
        translateX: ['210%', '105%'],
        duration: 400,
        easing: 'easeOutQuad',
      });
    };

    initFunc();
  }
}
export { Module_11_Plan_Slider };
