import utils from '../utils/utils';
import anime from '../libs/anime';
import globalVar from '../utils/globalVar';

class Archives {
  constructor() {
    let cache = {};
    const ppp = parseInt(document.querySelector('.template-archive .archive').dataset.nbpost);
    let cat = 'all'
    let offset = 0;

    const initFunc = () => {
      initCache();
      registerEvents();
    };

    const initCache = () => {
      cache.page = document.querySelector('.template-archive');
      cache.archive = document.querySelector('.template-archive .post_container');
      cache.cards = cache.archive.querySelectorAll('.card_article-big');
      cache.noResult = cache.page.querySelector('.no_result');
      cache.filters = cache.page.querySelectorAll('.filter_container .filter');
      cache.filterAll = cache.page.querySelector('.filter_container .filter-all');
      cache.btnMore = cache.page.querySelector('.load_more');
    };

    const registerEvents = () => {
      cache.filters.forEach(filter => {
        filter.addEventListener('click', filterCat);
      });
      cache.btnMore.addEventListener('click', loadMore);
    };

    const filterCat = (event) => {
      const btn = event.currentTarget;
      cat = btn.dataset.filter;

      if (!btn.classList.contains('active')) {
        offset = 0;
        cache.page.querySelector('.filter_container .active').classList.remove('active');
        btn.classList.add('active');
        const cards = cache.archive.querySelectorAll('.card_article-big:not(.hidden)');
        for (let i = 0; i < cards.length; i++) {
          anime({
              targets: cards[i],
              opacity: [1, 0],
              duration: 400,
              translateY: [0, '20%'],
              easing: 'easeOutQuad',
              complete: () => {
                if(i == cards.length-1) {
                  let nbHidden = cache.archive.querySelectorAll('.card_article-big.'+cat).length-1-ppp;
                  for (let ii = cache.cards.length-1; ii >= 0; ii--) {
                    if(cache.cards[ii].classList.contains(cat)) {
                      if(cache.cards[ii].classList.contains('hidden')) {
                        cache.cards[ii].classList.remove('hidden');
                      }
                      if(nbHidden < 0) {
                        cache.cards[ii].parentNode.insertBefore(cache.cards[ii], cache.cards[ii].parentNode.firstChild);
                        anime({
                           targets: cache.cards[ii],
                           opacity: [0, 1],
                           duration: 400,
                           translateY: ['-20%','0%'],
                           easing: 'easeOutQuad',
                         });
                      } else {
                        nbHidden--;
                        cache.cards[ii].classList.add('hidden');
                      }
                    } else {
                      if(!cache.cards[ii].classList.contains('hidden')) {
                        cache.cards[ii].classList.add('hidden');
                      }
                    }
                  };
                  if((cache.archive.querySelectorAll('.card_article-big.'+cat).length > ppp) && cache.btnMore.classList.contains('disabled')) {
                    cache.btnMore.classList.remove('disabled');
                  } else if((cache.archive.querySelectorAll('.card_article-big.'+cat).length <= ppp) && !cache.btnMore.classList.contains('disabled')) {
                    cache.btnMore.classList.add('disabled');
                  }
                }
              }
          });
        }
      }
    };

    const loadMore = () => {
      offset += ppp;
      const cards = cache.archive.querySelectorAll('.card_article-big.hidden.'+cat);
      let nbPost = ppp;
      if(cards.length <= ppp) {
        nbPost = cards.length;
        cache.btnMore.classList.add('disabled');
      }
      for (let i = 0; i < nbPost; i++) {
        cards[i].classList.remove('hidden');
        anime({
            targets: cards[i],
            opacity: [0, 1],
            duration: 400,
            translateY: ['-20%',0],
            easing: 'easeOutQuad',
        });
      }
    }

    initFunc();
  }
}

export { Archives };